<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>数据概况</span>
      </div>

      <!-- <el-card>
        <div slot="header" class="clearfix">
          <span>常用功能</span>
        </div>

        <el-row>
          <el-col :span="6" style="text-align: center">
            <el-link href="/#/theme/list"><span><i class="el-icon-video-camera-solid icon_btn"></i> 主题管理</span></el-link>
          </el-col>
          <el-col :span="6" style="text-align: center">
            <el-link href="/#/shopify/products"><span><i class="el-icon-s-goods icon_btn"></i> WOOO SELECT</span></el-link>
          </el-col>
          <el-col :span="6" style="text-align: center">
            <el-link href="/goods/product"><span><i class="el-icon-shopping-bag-2 icon_btn"></i> WOOO SERIES</span></el-link>
          </el-col>
          <el-col :span="6" style="text-align: center">
            <el-link href="/#/order/list"><span><i class="el-icon-document-copy icon_btn"></i> 订单管理</span></el-link>
          </el-col>
        </el-row>
      </el-card> -->

      <el-card style="margin-top: 10px; padding: 40px">
        <el-row>
          <el-col :span="4" style="text-align: center; font-size: 18px">
            <strong style="font-size: 24px; color: #409eff">{{ data1.num1 }}</strong>
            <p style="color: #999">对接成功合作伙伴</p>
          </el-col>

          <el-col :span="4" :offset="1" style="text-align: center; font-size: 18px">
            <strong style="font-size: 24px; color: #409eff">{{ data1.num2 }}</strong>
            <p style="color: #999">商品数</p>
          </el-col>

          <el-col :span="4" :offset="1" style="text-align: center; font-size: 18px">
            <strong style="font-size: 24px; color: #409eff">{{ data1.num3 }}</strong>
            <p style="color: #999">总用户数</p>
          </el-col>

          <el-col :span="4" :offset="1" style="text-align: center; font-size: 18px">
            <strong style="font-size: 24px; color: #409eff">{{ data1.num4 }}</strong>
            <p style="color: #999">总访客数</p>
          </el-col>

          <el-col :span="4" :offset="1" style="text-align: center; font-size: 18px">
            <strong style="font-size: 24px; color: #409eff">{{ data1.num5 }}</strong>
            <p style="color: #999">转移合作伙伴访客总数</p>
          </el-col>
        </el-row>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <span>经营概况</span>
        </div>

        <el-row>
          <el-col style="display: flex; align-items: center; just-content: center">
            <el-radio-group v-model="tab1">
              <el-radio-button
                v-for="tab in tab1s"
                :key="tab"
                :label="tab"
              ></el-radio-button>
            </el-radio-group>

            <el-date-picker
              style="margin-left: 20px"
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="9">
            <el-card class="c1" style="background: rgb(246, 250, 255)">
              <div slot="header" style="" class="clearfix">
                <span>支付数据</span>
              </div>
              <el-row
                :gutter="20"
                style="text-align: center; line-height: 40px; font-size: 18px"
              >
                <el-col :span="12">
                  <p>支付订单数（笔）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
                <el-col :span="12">
                  <p>支付订单数（笔）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
                <el-col :span="12" style="margin-top: 20px">
                  <p>支付订单数（笔）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
                <el-col :span="12" style="margin-top: 20px">
                  <p>支付订单数（笔）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>

          <el-col :span="9">
            <el-card class="c2" style="background: rgb(240, 254, 246)">
              <div slot="header" style="text-align: center" class="clearfix">
                <span>浏览数据</span>
              </div>
              <el-row
                :gutter="20"
                style="text-align: center; line-height: 40px; font-size: 18px"
              >
                <el-col :span="12">
                  <p>访客数（人）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>

                <el-col :span="12">
                  <p>访问量（次）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>

                <el-col :span="12" style="margin-top: 20px">
                  <p>转移合作伙伴访客（次）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="c3" style="background: rgb(255, 247, 233)">
              <div slot="header" style="text-align: center" class="clearfix">
                <span>收益数据</span>
              </div>
              <el-row
                :gutter="20"
                style="text-align: center; line-height: 40px; font-size: 18px"
              >
                <el-col :span="24">
                  <p>余额收益（元）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
                <el-col :span="24">
                  <p>支付订单数（笔）</p>
                  <p><strong style="font-size: 24px">0</strong></p>
                  <p style="color: #999">{{ 0 }} -</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <span>趋势概况</span>
        </div>

        <el-row>
          <el-col style="display: flex; align-items: center; just-content: center">
            <el-radio-group v-model="tab2">
              <el-radio-button :label="tab" v-for="tab in tab2s" :key="tab"></el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="6">
            <el-card
              :style="{ border: form1.t1 ? '1px solid rgb(64, 158, 255)' : '' }"
              style="
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                position: relative;
              "
              ><div @click="form1.t1 = !form1.t1">
                <p>移动访问人数</p>
                <p><strong style="font-size: 24px">0</strong></p>
                <el-checkbox
                  v-model="form1.t1"
                  style="position: absolute; right: 10px; top: 1px"
                ></el-checkbox>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              :style="{ border: form1.t2 ? '1px solid rgb(64, 158, 255)' : '' }"
              style="
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                position: relative;
              "
              ><div @click="form1.t2 = !form1.t2">
                <p>pc访问人数</p>
                <p><strong style="font-size: 24px">0</strong></p>
                <el-checkbox
                  v-model="form1.t2"
                  style="position: absolute; right: 10px; top: 1px"
                ></el-checkbox>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              :style="{ border: form1.t3 ? '1px solid rgb(64, 158, 255)' : '' }"
              style="
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                position: relative;
              "
              ><div @click="form1.t3 = !form1.t3">
                <p>小程序访问人数</p>
                <p><strong style="font-size: 24px">0</strong></p>
                <el-checkbox
                  v-model="form1.t3"
                  style="position: absolute; right: 10px; top: 1px"
                ></el-checkbox>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              :style="{ border: form1.t4 ? '1px solid rgb(64, 158, 255)' : '' }"
              style="
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                position: relative;
              "
            >
              <div @click="form1.t4 = !form1.t4">
                <p>支付商品数（件）</p>
                <p><strong style="font-size: 24px">0</strong></p>
                <el-checkbox
                  v-model="form1.t4"
                  style="position: absolute; right: 10px; top: 1px"
                ></el-checkbox>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div id="chart" style="width: 100%; height: 400px"></div>
      </el-card>

      <el-card style="margin-top: 10px">
        <el-row :gutter="20">
          <el-col :span="12"
            ><el-card style="margin-top: 10px">
              <div slot="header" class="clearfix">
                <span>合作伙伴购买力</span>
                <el-button
                  style="float: right; position: relative; bottom: 5px"
                  type="primary"
                  size="small"
                  >导出</el-button
                >
              </div>

              <el-table :data="tableData1" stripe style="width: 100%">
                <el-table-column prop="date" label="排名" width="180"> </el-table-column>
                <el-table-column prop="name" label="商品" width="180"> </el-table-column>
                <el-table-column prop="address" label="销售额"> </el-table-column>
              </el-table> </el-card
          ></el-col>

          <el-col :span="12"
            ><el-card style="margin-top: 10px">
              <div slot="header" class="clearfix">
                <span>用户购买力TOP排行</span>
                <el-button
                  style="float: right; position: relative; bottom: 5px"
                  type="primary"
                  size="small"
                  >导出</el-button
                >
              </div>
              <el-table :data="tableData2" stripe style="width: 100%">
                <el-table-column prop="date" label="排名" width="180"> </el-table-column>
                <el-table-column prop="name" label="用户" width="180"> </el-table-column>
                <el-table-column prop="address" label="支付金额"> </el-table-column>
              </el-table> </el-card
          ></el-col>
        </el-row>
      </el-card>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data1: {},
      tab1s: ["今日", "昨日", "前7日", "前30日", "汇总"],
      tab2s: ["今日", "昨日", "7日", "30日"],
      tab1: "今日",
      tab2: "今日",
      radio1: "",
      value1: "",
      form1: { t1: true, t2: true, t3: true, t4: true },
      role: 1,
      title: "添加",
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      tableData1: [],
      tableData2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      form: {},
      products: [],
      product: null,
      selProduct: { items: [] },
    };
  },

  created() {
    this.role = localStorage.getItem("role");
    // this.query();
    // this.query1();
    this.query_data1();
    let _this = this;
    setTimeout(() => {
      _this.chart();
    }, 1000);
  },

  methods: {
    query_data1() {
      this.$http
        .post("/admin/dashboard/data1")
        .then((res) => {
          if (res.status == 200) {
            this.data1 = res.data.data;
          }
        })
        .catch(() => {});
    },
    chart() {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
          data: ["支付订单数", "支付人数", "支付金额", "支付商品数"],
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: [
          {
            name: "支付订单数",
            type: "line",
            data: [10, 14, 23, 18, 19, 20, 10],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
          {
            name: "支付人数",
            type: "line",
            data: [10, 11, 13, 11, 12, 12, 9],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
          {
            name: "支付金额",
            type: "line",
            data: [100, 110, 130, 110, 1233, 1244, 955],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
          {
            name: "支付商品数",
            type: "line",
            data: [1, 11, 16, 50, 300, 14, 9],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };

      let myChart = this.$echarts.init(document.getElementById("chart"));
      myChart.setOption(option);
    },

    add_product() {
      if (this.product) {
        this.tableData1.push({
          product_id: this.product.id,
          tu_number: this.product.tu_number,
          product_name: this.product.product_name,
          end_date: this.$moment().format("YYYY-MM-DD"),
          status: 0,
        });
      }
    },

    add_items(item) {
      this.selProduct = item;
      if (this.selProduct["nodes"] == undefined) {
        this.selProduct["nodes"] = [];
      }

      this.dialogFormVisible1 = true;
    },

    add_nodes() {
      this.selProduct["nodes"].push({
        node_name: "",
        date: this.$moment().format("YYYY-MM-DD"),
        status: 0,
        remark: "",
      });
    },

    save_nodes() {
      this.$set(this.selProduct, "node_count", this.selProduct["nodes"].length);
      this.dialogFormVisible1 = false;
    },

    cancel_nodes() {
      this.selProduct["nodes"] = [];
      this.dialogFormVisible1 = false;
    },
    del_node(index) {
      this.selProduct["nodes"].splice(index, 1);
    },

    del_product(id) {
      let index = 0;
      this.tableData1.some((item) => {
        if (item.id == id) {
          return true;
        }
        index++;
      });
      this.tableData1.splice(index, 1);
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/project/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },

    query1() {
      this.$http
        .post("/admin/product/list", {})
        .then((res) => {
          this.products = res.data.data;
        })
        .catch(() => {});
    },
    del(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/project/delete", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    edit(row) {
      this.title = "修改";
      this.form = row;
      this.tableData1 = row.products;
      this.dialogFormVisible = true;
    },

    add() {
      this.form = {};
      this.tableData1 = [];
      this.dialogFormVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.tableData1.length > 0) {
            let products = [];
            this.tableData1.forEach((item) => {
              products.push({
                product_id: item.product_id,
                nodes: item.nodes,
              });
            });

            this.form.products = products;
            this.$http.post("/admin/project/save", this.form).then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            });
          } else {
            this.$message("请添加产品");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.c1 >>> .el-card__header {
  text-align: center;
  background: rgb(64, 158, 255);
  color: #fff;
}
.c2 >>> .el-card__header {
  text-align: center;
  background: rgb(106, 212, 151);
  color: #fff;
}
.c3 >>> .el-card__header {
  text-align: center;
  background: rgb(252, 159, 76);
  color: #fff;
}
.icon_btn {
  cursor: pointer;
  font-size: 40px;
  vertical-align: middle;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  background: rgb(64, 158, 255);
}
</style>
